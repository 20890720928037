<!--
 * @Author: 卢均锐morye
 * @Date: 2020-03-14 14:30:53
 * @LastEditTime: 2020-05-07 14:32:56
 -->
<template>
  <div class="app-container">
      <div class="titlebox">
          <el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
          <div class="title">绩效设置</div>
      </div>
      <el-tabs v-model="activeName">
          <el-tab-pane name="2" label="考核表模板" lazy><TemplateForm></TemplateForm></el-tab-pane>
          <el-tab-pane name="1" label="考核模板" lazy><Template ref="pane1" /></el-tab-pane>
      </el-tabs>
  </div>
</template>
<script>
import Template from './template';
import TemplateForm from './templateForm';
  export default {
    components: { Template, TemplateForm},
    data() {
      return {
        activeName: '2'
      };
    },
    mounted() {
      const params = JSON.parse(sessionStorage.getItem(`${this.$route.name}Data`));

      if (params && params.activeName) {this.activeName = params.activeName;}
    },
    beforeRouteLeave(to, from, next) {
      sessionStorage.setItem(`${this.$route.name}Data`, JSON.stringify({activeName: this.activeName}));
      next();
    },
    watch: {
      activeName(val) {
        if (val == 1) {
          this.$refs['pane1']._updatePane();
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
.sections-container {
    border-radius: 4px;
    padding: 40px 60px;
    background-color: #fff;
    overflow: hidden;
    margin-bottom:20px;
}
.titlebox {
  text-align: center;
  line-height: 17px;
  background: #fff;
  font: bold 18px/25px 'Microsoft YaHei';
  padding: 14px 40px;
  margin-bottom: 20px;
  > .el-button {
    float: left;
    color: #484848;
    transform: translateY(-8px);
    font-size: 16px;
  }
}
</style>
