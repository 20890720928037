<template>
	<div class="sections-container" v-loading="loading">
		<div class="section-wrap">
			<h3 class="title-icon">考核表模板</h3>
			<el-button style="float: right; transform: translateY(-45px)" type="primary" icon="el-icon-edit" size="small" @click="edit = true"
				>编辑考核表模板名称</el-button
			>
			<el-form label-width="140px" :model="form" ref="form" :rules="staffIdStatus != null ? rules : {}">
				<el-form-item prop="assessTemplateName" label="考核表模板名称">
					<span v-show="edit == false">
						<div
							class="mTabs"
							:key="index"
							v-for="(item, index) in tagList"
							@click="_tagClick(item, index)"
							:class="currentRowIndex === index ? 'act' : ''"
						>
							{{ item.assessTemplateName }}
						</div>
					</span>
					<span v-show="edit == true">
						<span v-for="(item, index1) in tagList" :key="'cg' + index1">
							<div class="mTabs" v-if="currentRowIndex !== index1" @click="_tagClick(item, index1)">
								{{ item.assessTemplateName }}
							</div>
							<el-input
								v-else
								style="width: 100px; width: 325px; margin-right: 10px"
								maxlength="20"
								show-word-limit
								v-model="item.assessTemplateName"
								size="small"
								:key="index1"
							></el-input>
						</span>
					</span>
					<el-input
						class="input-new-tag"
						v-if="staffIdStatus != null && inputVisible"
						v-model="inputValue"
						ref="saveTagInput"
						@change="_tagAdd"
						style="width: 325px"
						show-word-limit
						placeholder="输入考核表模板名称"
						maxlength="20"
						size="small"
					>
					</el-input>
					<el-button size="small" v-else-if="staffIdStatus != null && !inputVisible" class="button-new-tag" @click="inputVisible = true"
						>+ 添加模板</el-button
					>
				</el-form-item>
				<el-form-item prop="assessFileUrl" label="考核表模板">
					<el-upload
						ref="upload"
						class="upload-demo"
						action="#"
						:disabled="!form.assessTemplateName || (form.assessTemplateName && form.assessFileUrl.length != 0)"
						v-loading="contrachLoading"
						:file-list="form.assessFileUrl"
						element-loading-text="正在上传......"
						:http-request="onUploadChange"
						:show-file-list="false"
						:limit="1"
						multiple
						accept=".xls,.xlsx,.XLS,.XLSX"
					>
						<el-button
							size="small"
							type="primary"
							v-if="form.assessFileUrl.length == 0 && form.assessTemplateName && staffIdStatus != null"
							>点击上传</el-button
						>
						<div
							slot="tip"
							v-if="form.assessFileUrl.length == 0 && form.assessTemplateName && staffIdStatus != null"
							class="el-upload__tip"
						>
							文件格式为XLS/XLSX，大小在5MB内
						</div>
						<div v-if="form.assessFileUrl[0]" class="preview_top">
							<img :src="require('../../../../public/static/images/文档备份 2@3x.png')" style="vertical-align: middle" />
							<span>{{ form.assessFileUrl[0].name }}({{ renderSize(form.assessFileUrl[0].size || fileSize) }})</span>
							<el-link
								style="margin: 0 8px"
								type="primary"
								:underline="false"
								:href="`http://view.officeapps.live.com/op/view.aspx?src=${form.assessFileUrl[0].url}`"
								target="_blank"
								>预览</el-link
							>
							<el-link :underline="false" type="primary" @click="_delete" v-if="staffIdStatus != null">删除</el-link>
						</div>
						<!--<div  v-if="form.assessFileUrl" class="preview_top">
                                <img :src="require('../../../../public/static/images/文档备份 2@3x.png')" style="vertical-align: middle;" />
                                <span>{{form.assessFileUrl[0].name}}（{{fileSize || renderSize(form.assessFileUrl[0].size)}}）</span>
                            </div>!-->
					</el-upload>
					<div
						class="docContainer"
						style="width: 100%; display: flex"
						v-show="form.assessFileUrl.length == 0 && form.assessTemplateName && staffIdStatus != null"
					>
						<div class="docContent" style="width: auto">
							<img src="/static/images/fj.png" style="width: 40px; height: 40px; margin-right: 5px" />
							<div style="width: auto; height: 60px; display: inline-block">
								<span style="display: inline-block; transform: translateY(15px)">{{ `绩效考核模板文件` }}</span>
								<br />
								<!-- <a style="color:#409EFF;margin-right:5px;font-size:12px"
                                target='_blank'
                                :href="'http://view.officeapps.live.com/op/view.aspx?src='+'https://ehr1.oss-cn-hangzhou.aliyuncs.com/%E7%BB%A9%E6%95%88%E8%80%83%E6%A0%B8%E6%A8%A1%E6%9D%BF.xlsx'">预览</a>
                            <el-button type="text" size="small"
                                        @click="downLoadFile('https://ehr1.oss-cn-hangzhou.aliyuncs.com/%E7%BB%A9%E6%95%88%E8%80%83%E6%A0%B8%E6%A8%A1%E6%9D%BF.xlsx')">下载</el-button>
                            </div> -->
								<a
									style="color: #409eff; margin-right: 5px; font-size: 12px"
									target="_blank"
									:href="
										'http://view.officeapps.live.com/op/view.aspx?src=' +
										'https://ehr-prod.oss-cn-shenzhen.aliyuncs.com/template/%E7%BB%A9%E6%95%88%E8%80%83%E6%A0%B8%E6%A8%A1%E6%9D%BF.xlsx'
									"
									>预览</a
								>
								<el-button
									type="text"
									size="small"
									@click="
										downLoadFile(
											'https://ehr-prod.oss-cn-shenzhen.aliyuncs.com/template/%E7%BB%A9%E6%95%88%E8%80%83%E6%A0%B8%E6%A8%A1%E6%9D%BF.xlsx'
										)
									"
									>下载</el-button
								>
							</div>
						</div>
					</div>
				</el-form-item>
				<el-form-item prop="assessNote" label="考核表模板说明">
					<el-input
						:readonly="staffIdStatus == null"
						v-model="form.assessNote"
						placeholder="请输入考核表模板说明"
						type="textarea"
						minlength="20"
						maxlength="1000"
						show-word-limit
						:autosize="{ minRows: 5, maxRows: 10 }"
					/>
				</el-form-item>
			</el-form>
		</div>
		<el-row style="text-align: center" v-if="staffIdStatus != null">
			<el-button type="primary" @click="_save">保存</el-button>
		</el-row>
	</div>
</template>
<script>
import { performanceTableTemplateSave, performanceTableTemplateList } from '@/api/performance';
export default {
	data() {
		return {
			imgUrl: '/public/static/images/fj.png',
			inputVisible: false,
			edit: false,
			inputValue: '',
			tagList: [],
			contrachLoading: false,
			form: {
				id: '',
				assessTemplateName: '',
				assessFileUrl: [],
				assessNote: ''
			},
			fileSize: '',
			rules: {
				assessTemplateName: [{ required: true, message: '请选择模板', trigger: 'blur' }],
				assessFileUrl: [{ required: true, message: '请上传文件', trigger: 'blur' }],
				assessNote: [{ required: true, message: '请输入审批内容', trigger: 'blur' }]
			},
			currentRow: {},
			currentRowIndex: '',
			loading: false,
			staffIdStatus: this.$store.state.app.staffId
		};
	},
	methods: {
		_getList(flag) {
			performanceTableTemplateList({}).then((res) => {
				this.tagList = res.list;
				if (res.list.length > 0 && !flag) {
					this._tagClick(res.list[0], 0);
					// }else if(res.list.length > 0 && flag){
					// this._tagClick(res.list[res.list.length-1],res.list.length-1)
				}
			});
		},
		onUploadChange(event) {
			const isLt50M = event.file.size / 1024 / 1024 < 5;

			if (!isLt50M) {
				this.$message.warning('大小不得超过5M');
				return false;
			}
			this.fileSize = this.renderSize(event.file.size);
			const formData = new FormData();

			formData.append('imgFile', event.file);
			const type = 'multipart/form-data;boundary=--------------------56423498738365';

			this.contrachLoading = true;
			this.$axios.post('/v1/common/upload/file', formData, { headers: { 'Content-Type': type } }).then((res) => {
				this.form.assessFileUrl = [{ name: res.fileName, size: event.file.size, url: res.httpUrl, bucketName: res.bucketName, key: res.key }];
				this.contrachLoading = false;
			});
		},
		renderSize(value) {
			if (value == null || value == '') {
				return '0 Bytes';
			}
			const unitArr = new Array('Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB');

			let index = 0;

			const srcsize = parseFloat(value);

			index = Math.floor(Math.log(srcsize) / Math.log(1024));
			let size = srcsize / Math.pow(1024, index);

			size = size.toFixed(2); // 保留的小数位数
			return size + unitArr[index];
		},
		downLoadFile(url) {
			window.open(url);
		},
		_save() {
			this.$refs['form'].validate((valid) => {
				if (valid) {
					const _form = { ...this.form };

					_form.assessTemplateName = this.tagList[this.currentRowIndex].assessTemplateName;
					_form.assessFileUrl = JSON.stringify(this.form.assessFileUrl || this.form.assessFileUrlList);
					this.loading = true;
					performanceTableTemplateSave(_form).then((res) => {
						this.loading = false;
						if (res.id) {
							this.edit = false;
							this.$message.success(`${this.form.id ? '修改' : '新增'}成功`);
							this._getList(this.currentRowIndex);
						}
					});
				}
			});
		},
		_tagAdd() {
			if (this.inputValue) {
				const { inputValue } = this;

				for (const key in this.tagList) {
					if (this.tagList[key].assessTemplateName == inputValue) {
						this.$message.error('已存在相同名称');
						return false;
					}
				}
				this.tagList.push({ assessTemplateName: inputValue, assessFileUrlList: [] });
			}
			this._tagClick(this.tagList[this.tagList.length - 1], this.tagList.length - 1);
			this.inputVisible = false;
			this.inputValue = '';
		},
		_tagClick(item, index) {
			this.$refs['upload'].clearFiles();
			this.form.id = item.id;
			this.form.assessTemplateName = item.assessTemplateName;
			this.form.assessNote = item.assessNote;
			this.form.assessFileUrl = item.assessFileUrlList;
			this.currentRowIndex = index;
			this.currentRow = { ...item };
		},
		_delete() {
			this.currentRow = '';
			this.form.assessFileUrl = [];
		}
	},
	mounted() {
		this._getList();
	}
};
</script>
<style lang="scss" scoped>
.sections-container {
	overflow: hidden;
	background-color: #fff;
	.title-icon {
		font-size: 16px;
		margin: 0 0 20px 10px;
		&:before {
			display: inline-block;
			width: 8px;
			height: 18px;
			background: #498df0;
			content: ' ';
			border-radius: 5px;
			vertical-align: top;
			margin-right: 5px;
		}
	}
	.mTabs {
		background: #ffffff;
		border: 1px solid #c5cdda;
		border-radius: 4px;
		border-radius: 4px;
		line-height: 32px;
		padding: 0 10px;
		display: inline-block;
		margin-right: 24px;
		font-family: PingFangSC-Regular;
		font-size: 16px;
		color: #2b3642;
		margin-bottom: 10px;
		cursor: pointer;
	}
	.mTabs.act {
		border-color: #498df0;
		color: #498df0;
	}
	.preview_top {
		background: #f4f4f5;
		border-radius: 4px;
		border-radius: 4px;
		text-align: center;
		margin-bottom: 14px;
		display: inline-block;
		padding: 0 20px;
		img {
			width: 14px;
			height: 14px;
			margin-right: 10px;
		}
	}
}
::v-deep.el-form-item__label {
	font-weight: normal;
}
</style>
