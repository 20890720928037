<template>
	<div>
		<el-form
			:model="searchForm"
			inline
			ref="form"
			class="searchForm"
			label-width="100px"
			size="small"
			style="background-color: #fff; padding: 15px 20px 0 20px"
		>
			<el-form-item label="绩效考核名">
				<el-select v-model="searchForm.performanceId" clearable filterable placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.value" :value="item.key"> </el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="年份">
				<el-date-picker type="year" v-model="searchForm.year" placeholder="选择年份" value-format="yyyy" format="yyyy" clearable>
				</el-date-picker>
			</el-form-item>
			<el-button type="primary" size="small" @click="_getList(1)" icon="el-icon-search">查询</el-button>
			<!--<el-button type="primary" class="fr" size="small" @click="$router.push({name: 'AchievementsCreate'})">新建绩效</el-button>!-->
		</el-form>
		<div style="background-color: #fff; padding-bottom: 20px; margin: 20px 0">
			<el-table :data="list" stripe :header-cell-style="tableHeaderColor" @row-click="_handleTableRowClick">
				<el-table-column fixed prop="assessTemplateName" label="绩效考核模板" show-overflow-tooltip width="280"> </el-table-column>
				<el-table-column prop="评分制度" label="评分制度" align="center">
					<template>100分</template>
				</el-table-column>
				<el-table-column v-for="(col, i) in columns" :prop="col.prop" :key="i" :label="col.label" show-overflow-tooltip align="center">
				</el-table-column>
			</el-table>
			<el-pagination
				v-if="searchForm.totalNum > 0"
				@size-change="_handleSizeChange"
				@current-change="_handleCurrentChange"
				:current-page="searchForm.pageNo"
				:page-sizes="[20, 40, 80, 100]"
				:page-size="searchForm.pageSize"
				style="text-align: right; margin-top: 20px"
				layout="total, sizes, prev, pager, next, jumper"
				:total="searchForm.totalNum"
			>
			</el-pagination>
		</div>
	</div>
</template>
<script>
import { performanceTemplateList, performanceNameChoice } from '@/api/performance';
export default {
	data() {
		return {
			searchForm: {
				performanceId: '',
				year: '',
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			list: [],
			options: [],
			columns: [
				{ label: '考核范围', prop: 'assessRange' },
				{ label: '考核周期', prop: 'cycle' },
				{ label: '考核时间', prop: 'assessCycle' },
				{ label: '操作人', prop: 'createName' },
				{ label: '操作时间', prop: 'operationTime' }
			],
			staffIdStatus: this.$store.state.app.staffId
		};
	},
	methods: {
		_getList(page) {
			page ? (this.searchForm.pageNo = page) : '';
			performanceTemplateList(this.searchForm).then((res) => {
				this.list = res.list;
				this.searchForm.totalNum = res.totalNum;
			});
		},
		_handleSizeChange(val) {
			this.searchForm.pageSize = val;
			this._getList();
		},
		_handleCurrentChange(val) {
			this.searchForm.pageNo = val;
			this._getList();
		},
		_getperformanceNameChoice() {
			performanceNameChoice({}).then((res) => {
				this.options = res.list;
			});
		},
		_updatePane() {
			this._getperformanceNameChoice();
			this._getList(this.searchForm.pageNo);
		},
		_handleTableRowClick(row) {
			this.$router.push({
				name: 'AchievementsCreate',
				query: { assessmentid: row.id, readonly: 1 }
			});
		},
		tableHeaderColor({ rowIndex }) {
			if (rowIndex === 0) {
				return 'background-color: #F0F8FF;';
			}
		}
	},
	mounted() {
		this._getperformanceNameChoice();
		this._getList();
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
</style>
